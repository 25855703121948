import React, { useEffect, useState, useRef } from "react";
import { Card, Carousel, Typography } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const { Title } = Typography;

const popularDestinations = [
  {
    id: 1,
    name: "Dar es Salaam",
    image: "../assets/images/dar.jpeg",
    properties: 1234,
    rating: 4.5,
  },
  {
    id: 2,
    name: "Zanzibar",
    image: "../assets/images/zanzibar.jpeg",
    properties: 2345,
    rating: 4.7,
  },
  {
    id: 3,
    name: "Arusha",
    image: "../assets/images/arusha.jpeg",
    properties: 3456,
    rating: 4.6,
  },
  {
    id: 4,
    name: "Dodoma",
    image: "../assets/images/dodoma.jpeg",
    properties: 4567,
    rating: 4.8,
  },
  {
    id: 5,
    name: "Dodoma",
    image: "../assets/images/dodoma.jpeg",
    properties: 4567,
    rating: 4.8,
  },{
    id: 6,
    name: "Dodoma",
    image: "../assets/images/dodoma.jpeg",
    properties: 4567,
    rating: 4.8,
  },{
    id: 7,
    name: "Dodoma",
    image: "../assets/images/dodoma.jpeg",
    properties: 4567,
    rating: 4.8,
  },{
    id: 8,
    name: "Dodoma",
    image: "../assets/images/dodoma.jpeg",
    properties: 4567,
    rating: 4.8,
  },{
    id: 9,
    name: "Dodoma",
    image: "../assets/images/dodoma.jpeg",
    properties: 4567,
    rating: 4.8,
  },{
    id: 10,
    name: "Dodoma",
    image: "../assets/images/dodoma.jpeg",
    properties: 4567,
    rating: 4.8,
  },
];

const WeekendDeals = () => {
  const [hotels, setHotels] = useState([]);
  const activeTab = useSelector((state) => state.tab.activeTab);
  const dispatch = useDispatch();
  const history = useHistory();
  const carouselRef = useRef(null);

  useEffect(() => {

  }, [dispatch, activeTab]);

  const handleHotelClick = (id) => {
    history.push(`/hotel/${id}`);
  };

  return (
    <div style={{ maxWidth: "1400px", margin: "0 auto", padding: "20px 10px" }}>
      {/* Title Section */}
      <Title level={2} style={{ textAlign: "left", marginBottom: "20px" }}>
        Popular destinations
      </Title>

      <div style={{ position: "relative" }}>
        {/* Navigation Buttons */}
        <button
          onClick={() => carouselRef.current.prev()}
          style={{
            position: "absolute",
            top: "50%",
            left: "-30px",
            transform: "translateY(-50%)",
            background: "white",
            border: "none",
            cursor: "pointer",
            fontSize: "24px",
            zIndex: 2,
            padding: "5px",
            borderRadius: "50%",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",
          }}
        >
          <LeftOutlined />
        </button>

        <button
          onClick={() => carouselRef.current.next()}
          style={{
            position: "absolute",
            top: "50%",
            right: "-30px",
            transform: "translateY(-50%)",
            background: "white",
            border: "none",
            cursor: "pointer",
            fontSize: "24px",
            zIndex: 2,
            padding: "3px",
            borderRadius: "50%",
            boxShadow: "0 2px 5px rgba(0, 0, 0, 0.2)",

          }}
        >
          <RightOutlined />
        </button>

        {/* Carousel */}
        <Carousel
          ref={carouselRef}
          autoplay
          dots={{ className: "custom-dots" }}
          responsive={[
            { breakpoint: 1200, settings: { slidesToShow: 4 } },
            { breakpoint: 900, settings: { slidesToShow: 3 } },
            { breakpoint: 600, settings: { slidesToShow: 2 } },
            { breakpoint: 400, settings: { slidesToShow: 1 } },
          ]}
          slidesToShow={4}
          slidesToScroll={1}
          infinite
        >
          {popularDestinations.map((destination) => (
            <div
              key={destination.id}
              style={{ display: "flex", justifyContent: "center", padding: "10px" }}
            >
              <Card
                hoverable
                cover={<img src={destination.image} alt={destination.name} style={{ height: "200px", objectFit: "cover" }} />}
                style={{
                  width: "300px",
                  borderRadius: "8px",
                  boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
                  height: "350px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  padding: "2px",
                }}
                onClick={() => handleHotelClick(destination.id)}
              >
                <Card.Meta
                  title={destination.name}
                  description={
                    <>
                      <p style={{ marginBottom: "5px", fontSize: "14px", color: "#555" }}>
                        {destination.properties} properties
                      </p>
                      <p style={{ fontWeight: "bold", color: "#333" }}>
                        ⭐ {destination.rating} rating
                      </p>
                    </>
                  }
                />
              </Card>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default WeekendDeals;
