// import React from 'react';

// const HeroSection = () => {
//   // Inline styles for the component
//   const heroSectionStyle = {
//     position: 'relative', // To enable overlay and wave positioning
//     backgroundColor: '#00092C', // Oceanic blue background
//     color: '#FF5F00', // Text color (black)
//     width: '100vw', // Set width to 100% of viewport width for full responsiveness
//     minHeight: '500px', // Adjusted minimum height for larger screens
//     padding: '50px 0', // Padding for top and bottom, but none on the sides to fit the width
//     margin: 0, // Remove any default margin
//     textAlign: 'center', // Center the content
//     overflow: 'hidden', // Prevent overflow from wave
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center',
//     alignItems: 'center',
//     boxSizing: 'border-box', // Ensure padding and border are included in width/height
//   };

//   const overlayStyle = {
//     position: 'absolute',
//     top: 0,
//     left: 0,
//     width: '100%',
//     height: '100%',
//     // backgroundColor: 'rgba(0, 0, 0, 0.3)', // Black overlay with opacity
//     zIndex: 1, // Ensure it's behind the text but in front of the background
//   };

//   const textStyle = {
//     position: 'relative', // To make sure text stays above the overlay
//     zIndex: 2,
//     display: 'flex',
//     flexDirection: 'column',
//     justifyContent: 'center', // Center the text vertically
//     height: '100%', // Take full height to center text
//   };

//   const buttonStyle = {
//     marginTop: '20px',
//     padding: '12px 24px', // Adjusted padding for primary button
//     backgroundColor: '#ff7f50', // Coral color for the button
//     color: 'black',
//     border: 'none',
//     borderRadius: '5px',
//     cursor: 'pointer',
//     fontSize: '18px', // Larger font size for emphasis
//     fontWeight: 'bold', // Make text bold
//     textDecoration: 'none', // Remove underline from the link
//     transition: 'background-color 0.3s ease', // Transition effect
//   };

//   const waveStyle = {
//     position: 'absolute',
//     bottom: 0, // Attach wave to the bottom
//     left: 0, // Ensure it starts from the very left
//     width: '100vw', // 100% of viewport width to ensure full coverage
//     zIndex: 1, // Positioned under the content
//   };

//   // Hover effect for button
//   const handleMouseEnter = (e) => {
//     e.currentTarget.style.backgroundColor = '#B20600'; // Darker coral color on hover
//   };

//   const handleMouseLeave = (e) => {
//     e.currentTarget.style.backgroundColor = '#B20600'; // Reset to original color
//   };

//   return (
//     <section style={heroSectionStyle}>

//       <div style={overlayStyle}></div>
//       <div style={textStyle}>
//         <h1>Bongo Hotels</h1>
//         <p>Experience luxury and comfort in the heart of the city.</p>
//         {/* Uncomment this button to make it functional */}
//         <button
//           style={buttonStyle}
//           onMouseEnter={handleMouseEnter}
//           onMouseLeave={handleMouseLeave}
//           onClick={() => window.location.href="/HomeTest"}
//         >
//           Book Now
//         </button>
//       </div>
//       <svg
//         style={waveStyle}
//         xmlns="http://www.w3.org/2000/svg"
//         viewBox="0 0 1440 320"
//       >
//         <path
//           fill="#ffffff"
//           fillOpacity="1"
//           d="M0,288L48,272C96,256,192,224,288,213.3C384,203,480,213,576,218.7C672,224,768,224,864,202.7C960,181,1056,139,1152,144C1248,149,1344,203,1392,229.3L1440,256V320H1392C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320H0Z"
//         ></path>
//       </svg>
//     </section>
//   );
// };

// export default HeroSection;



import React from 'react';
import { Layout, Typography } from 'antd';
import 'antd/dist/antd.css';
import SearchComponent from '../../../../components/SearchComponent/SearchComponent';

const { Header, Content } = Layout;
const { Title } = Typography;

const HeroSection = () => {
  return (
    <Layout>
      <Content>
        <div
          style={{
            position: 'relative',
            height: '50vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            textAlign: 'center',
            color: '#fff',
          }}
        >
          <video
            autoPlay
            loop
            muted
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
          >
            <source
              src="/assets/Hero.mp4" // Replace with your video URL
              type="video/mp4"
            />
          </video><div
            style={{

              position: 'relative',
              zIndex: 1, // Ensure the text is above the video
            }}
          >
            <Title l
            evel={1} style={{ fontSize: '5rem', color: 'white', fontWeight: 'bold' }}>
              Find your next stay
            </Title>
            <p style={{ fontSize: '1.5rem' }}>Search deals on hotels, homes, and much more...</p>
          </div>


        </div>
      </Content>
    </Layout>
  );
};

export default HeroSection;

