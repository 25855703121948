import React, { useRef, useState, useEffect } from "react";
import { Card, Carousel } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom"; // For React Router v5
import axios_ from "../../../util/axios"; // Assuming axios is configured

const HotelTypesSwiper = () => {
  const [hotelTypes, setHotelTypes] = useState([]);
  const carouselRef = useRef(null);
  const history = useHistory(); // For React Router v5
  // const navigate = useNavigate(); // For React Router v6

  // Fetch hotel types from the API
  useEffect(() => {
    const fetchHotelTypes = async () => {
      try {
        const response = await axios_.get('/property-types'); // Replace with your actual API endpoint
        setHotelTypes(response.data); // Assuming the response is an array of hotel types
      } catch (error) {
        console.error("Error fetching hotel types:", error);
      }
    };

    fetchHotelTypes();
  }, []);

  const handleCardClick = (hotelType) => {
    history.push(`/apiPage?type=${encodeURIComponent(hotelType)}`);
    // navigate(`/apiPage?type=${encodeURIComponent(hotelType)}`); // For React Router v6
  };

  return (
    <div style={{ width: "100%", maxWidth: "1400px", margin: "0 auto", padding: "5px", position: "relative" }}>
      <button
        onClick={() => carouselRef.current.prev()}
        style={{ position: "absolute", top: "50%", left: "-40px", transform: "translateY(-50%)", background: "white", border: "none", cursor: "pointer", fontSize: "24px", zIndex: 1 }}
      >
        <LeftOutlined />
      </button>
      <button
        onClick={() => carouselRef.current.next()}
        style={{ position: "absolute", top: "50%", right: "-40px", transform: "translateY(-50%)", background: "white", border: "none", cursor: "pointer", fontSize: "24px", zIndex: 1 }}
      >
        <RightOutlined />
      </button>
      <Carousel
        ref={carouselRef}
        autoplay
        dots={{ className: "custom-dots" }}
        slidesToShow={3} // Ensures 5 cards in a row
        slidesToScroll={1}
        infinite
        responsive={[
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4, // For smaller screens
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 800,
            settings: {
              slidesToShow: 3, // For even smaller screens
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 500,
            settings: {
              slidesToShow: 1, // For mobile screens
              slidesToScroll: 1,
            },
          },
        ]}
      >
        {hotelTypes.map((hotel, index) => (
          <div key={index} style={{ display: "flex", justifyContent: "center", padding: "10px" }}>
            <Card
              hoverable
              cover={<img src={'../../../../assets/images/samwel-francis-qn4QRM9dj9U-unsplash (1).jpg'} alt={hotel.type} style={{ height: "200px", objectFit: "cover" }} />}
              style={{ width: "250px", borderRadius: "8px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
              onClick={() => handleCardClick(hotel.type)}
            >
              <Card.Meta title={hotel.name} description={`${hotel.count} available`} />
            </Card>
          </div>
        ))}
      </Carousel>
      {/* Display the count of the properties */}
      {/* <div style={{ marginTop: "20px", textAlign: "left" }}>
        <h5>Total Number of Properties: {hotelTypes.length}</h5>
      </div> */}
    </div>
  );
};

export default HotelTypesSwiper;




// import React, { useRef } from "react";
// import { Card, Carousel } from "antd";
// import { LeftOutlined, RightOutlined } from "@ant-design/icons";
// import { useHistory } from "react-router-dom"; // For React Router v5
// // import { useNavigate } from "react-router-dom"; // For React Router v6

// const hotelTypes = [
//   { type: "Luxury", count: 25, image: "/images/luxury.jpg" },
//   { type: "Budget", count: 40, image: "/images/budget.jpg" },
//   { type: "Resort", count: 15, image: "/images/resort.jpg" },
//   { type: "Boutique", count: 10, image: "/images/boutique.jpg" },
//   { type: "Business", count: 30, image: "/images/business.jpg" },
//   { type: "Resortsdf", count: 15, image: "/images/resort.jpg" },
//   { type: "Boutiquefe", count: 10, image: "/images/boutique.jpg" },
//   { type: "Businesserf", count: 30, image: "/images/business.jpg" },
// ];

// const HotelTypesSwiper = () => {
//   const carouselRef = useRef(null);
//   const history = useHistory(); // For React Router v5
//   // const navigate = useNavigate(); // For React Router v6

//   const handleCardClick = (hotelType) => {
//     history.push(`/apiPage?type=${encodeURIComponent(hotelType)}`);
//     // navigate(`/apiPage?type=${encodeURIComponent(hotelType)}`); // For React Router v6
//   };

//   return (
//     <div style={{ width: "100%", maxWidth: "1400px", margin: "0 auto", padding: "5px", position: "relative" }}>
//       <button
//         onClick={() => carouselRef.current.prev()}
//         style={{ position: "absolute", top: "50%", left: "-40px", transform: "translateY(-50%)", background: "white", border: "none", cursor: "pointer", fontSize: "24px", zIndex: 1 }}
//       >
//         <LeftOutlined />
//       </button>
//       <button
//         onClick={() => carouselRef.current.next()}
//         style={{ position: "absolute", top: "50%", right: "-40px", transform: "translateY(-50%)", background: "white", border: "none", cursor: "pointer", fontSize: "24px", zIndex: 1 }}
//       >
//         <RightOutlined />
//       </button>
//       <Carousel ref={carouselRef} autoplay dots={{ className: "custom-dots" }} slidesToShow={5} slidesToScroll={1} infinite>
//         {hotelTypes.map((hotel, index) => (
//           <div key={index} style={{ display: "flex", justifyContent: "center", padding: "10px" }}>
//             <Card
//               hoverable
//               cover={<img src={hotel.image} alt={hotel.type} style={{ height: "200px", objectFit: "cover" }} />}
//               style={{ width: "250px", borderRadius: "8px", boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)" }}
//               onClick={() => handleCardClick(hotel.type)}
//             >
//               <Card.Meta title={hotel.type} description={`${hotel.count} available`} />
//             </Card>
//           </div>
//         ))}
//       </Carousel>
//     </div>
//   );
// };

// export default HotelTypesSwiper;
