import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Typography,
  DatePicker,
  Select,
} from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { setActiveTab } from "../userRedux/actions/tabAction";
import "../styles/Home.less";
import axios_ from "../../../util/axios";
import Paragraph from "antd/lib/skeleton/Paragraph";
import HomeTest from "../../Home.js";
import ClientHero from "../components/ClientHero/index";
import Destination from "../../../components/Destination/destination";
import ByProperties from "../../../components/AllHotels/ByProperties/byProperties";
import SearchComponent from "../../../components/SearchComponent/SearchComponent";
import AllHotels from "../../../components/AllHotels/allhotels";
import ClientFooter from "../components/ClientFooter/index";
import WeekendDeals from "../components/WeekendDeals/index.js";
import PopularDestinations from "../components/PopularDestinations/index.js";
import LastVisitedHotels from "../components/LastVisited/index.js";


const { Title, Text, Link } = Typography;
const { Option } = Select;





const Home = () => {
  const [showSearchBox, setShowSearchBox] = useState(true);
  const [hotels, setHotels] = useState([]);
  const activeTab = useSelector((state) => state.tab.activeTab);
  const dispatch = useDispatch();
  const history = useHistory();
  const [images, setImages] = useState([]);

  useEffect(() => {
    if (!activeTab) {
      dispatch(setActiveTab("stays"));
    }
  }, [dispatch, activeTab]);

  useEffect(() => {
    const fetchHotels = async () => {
      try {
        const { data } = await axios_.get("/dashboard");
        // console.log(data)
        const images = data.map((hotel) =>
          hotel.images.map((image) => image.imageUrl)
        );
        setHotels(
          data.map((hotel, index) => ({
            ...hotel,
          }))
        );
      } catch (error) {
        console.error("Error fetching hotels:", error);
      }
    };

    fetchHotels();
  }, []);

  const handleHotelClick = (id) => {
    history.push(`/hotel/${id}`);
  };



  const onSearch = (searchQuery) => {
    console.log(searchQuery);
    axios_.get(`/search?query=${searchQuery}`).then((res) => {
      console.log(res.data);
    });
    // history.push(`/search?query=${searchQuery}`);
  };

  return (

    <div className="home-content">
      <div className="hero-section">
        <ClientHero />
      </div>
      <div
        style={{
          position: "relative",
          zIndex: 3, // Ensure the text is above the video
          marginTop: "-40px", // Negative margin
        }}
      >
        <SearchComponent onSearch={onSearch} />{" "}
      </div>

{/* <div><LastVisitedHotels /></div> */}
      <div>
        <PopularDestinations />
      </div>
      <div><WeekendDeals/></div>
      {/* <div className="content-section">
        <Title level={2}>Popular destinations</Title>
        <Row gutter={[16, 16]}>
          {popularDestinations.map((destination) => (
            <Col xs={24} sm={12} md={6} key={destination.id}>
              <Card
                hoverable
                cover={<img alt={destination.name} src={destination.image} />}
                className="destination-card"
                onClick={() => handleHotelClick(destination.id)}
              >
                <Card.Meta
                  title={destination.name}
                  description={`${destination.properties} properties`}
                />
                <Rate
                  disabled
                  defaultValue={destination.rating}
                  className="destination-rating"
                />
              </Card>
            </Col>
          ))}
        </Row>

        <Title level={2} className="section-title">
          Deals for the weekend
        </Title>
        <Row gutter={[16, 16]}>
          {deals.map((deal) => (
            <Col xs={24} sm={12} md={8} key={deal.id}>
              <Card
                hoverable
                cover={<img alt={deal.title} src={deal.image} />}
                className="deal-card"
                onClick={() => handleHotelClick(deal.id)}
              >
                <Card.Meta
                  title={deal.title}
                  description={
                    <div>
                      <Text>{deal.location}</Text>
                      <div className="deal-rating">
                        <Rate disabled defaultValue={deal.rating} />
                        <Text className="review-count">
                          ({deal.reviews} reviews)
                        </Text>
                      </div>
                      <Text className="deal-price">
                        Starting from ${deal.price}
                      </Text>
                    </div>
                  }
                />
              </Card>
            </Col>
          ))}
        </Row>
      </div> */}

      <div>
      <Title level={2} className="section-titlee">
          Browse by properties
        </Title>
        <ByProperties /></div>
      <div>
        <Destination />
      </div>

      <div>
        <AllHotels />
      </div>


      <div style={{paddingTop:'10px'}}><ClientFooter /></div>
    </div>
  );
};

export default Home;
