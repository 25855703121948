import React, { useState, useEffect } from 'react';
import { Card, Typography } from 'antd';

const { Title, Text } = Typography;

const words = ['Hotel', 'Villa', 'Hostel', 'Lodge'];
const intervalTime = 3000; // Time between word changes in milliseconds

const SwipeCard = () => {
  const [currentWord, setCurrentWord] = useState(0);
  const [animate, setAnimate] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setAnimate(true); // Trigger animation
      setTimeout(() => {
        setCurrentWord((prev) => (prev + 1) % words.length); // Change word
        setAnimate(false); // Reset animation
      }, 500); // Delay to allow animation to complete
    }, intervalTime);

    return () => clearInterval(interval); // Cleanup interval
  }, []);

  return (
    <Card
      style={{
        width: '100%',
        textAlign: 'center',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        borderRadius: '10px',
        padding: '20px',
      }}
    >
      <Title level={1} style={{ fontSize: '1.5rem', margin: '10px 0' }}>
        Not satisfied with the <br />
        <span
          style={{
            display: 'inline-block',
            textDecoration: 'underline',
            fontWeight: 'bold',
            color: '#1890ff',
            overflow: 'hidden',
            height: '30px', // Fixed height for the sliding effect
          }}
        >
          <div
            style={{
              transform: animate ? 'translateY(-40px)' : 'translateY(0)',
              transition: 'transform 0.5s ease-in-out ',
            }}
          >
            {words[currentWord]}
          </div>
          {/* <div
            style={{
              transform: animate ? 'translateY(-40px)' : 'translateY(0)',
              transition: 'transform 0.8s ease-in-out',
            }}
          >
            {words[(currentWord + 1) % words.length]}
          </div> */}
        </span>{' '}
        <br />
        we suggested for you?
      </Title>
      <Text type="secondary" style={{ fontSize: '1rem', marginTop: '10px',font:'italic',fontWeight:'bold' }}>
        <a href="/HomeTest" style={{ textDecoration: '', color: '#1890ff' }}>
          Check all our Hotels here
        </a>
      </Text>
    </Card>
  );
};

export default SwipeCard;
