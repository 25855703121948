import React, { useState, useEffect } from "react";
import { Card, Typography, Button } from "antd";
import { useHistory } from "react-router-dom"; // If you are using React Router for navigation

const { Title } = Typography;

const LastVisitedHotels = () => {
  const [lastVisited, setLastVisited] = useState([]);
  const history = useHistory();

  // Fetch the last visited hotels from localStorage when the component mounts
  useEffect(() => {
    const visitedHotels = JSON.parse(localStorage.getItem('lastVisitedHotels')) || [];
    setLastVisited(visitedHotels);
  }, []);

  // Handle click on a hotel card to navigate to the hotel details page
  const handleHotelClick = (hotelId) => {
    history.push(`/hotel/${hotelId}`); // Navigates to the hotel's detail page
  };

  // Function to clear the last visited hotels from localStorage
  const clearVisitedHotels = () => {
    localStorage.removeItem('lastVisitedHotels');
    setLastVisited([]); // Optionally clear the state as well
  };

  return (
    <div style={{ maxWidth: "1400px", margin: "0 auto", padding: "20px 10px" }}>
      <Title level={2} style={{ textAlign: "left", marginBottom: "20px" }}>
        Last Visited Hotels
      </Title>

      {/* Button to clear last visited hotels */}
      <Button
        type="primary"
        onClick={clearVisitedHotels}
        style={{ marginBottom: "20px", backgroundColor: "#ff4d4f", borderColor: "#ff4d4f" }}
      >
        Clear Last Visited Hotels
      </Button>

      <div style={{ display: "flex", flexWrap: "wrap", gap: "20px" }}>
        {/* Display the last 5 visited hotels */}
        {lastVisited.slice(0, 5).map((hotel) => (
          <Card
            key={hotel.id}
            hoverable
            cover={<img src={hotel.image} alt={hotel.title} style={{ height: "200px", objectFit: "cover" }} />}
            style={{
              width: "280px",
              borderRadius: "8px",
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
              height: "350px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
            onClick={() => handleHotelClick(hotel.id)}
          >
            <Card.Meta
              title={hotel.title}
              description={
                <>
                  <p style={{ marginBottom: "5px", fontSize: "14px", color: "#555" }}>
                    {hotel.location}
                  </p>
                  <p style={{ fontWeight: "bold", color: "#333" }}>${hotel.price} per night</p>
                  <p style={{ fontSize: "13px", color: "#777" }}>
                    ⭐ {hotel.rating} ({hotel.reviews} reviews)
                  </p>
                </>
              }
            />
          </Card>
        ))}
      </div>
    </div>
  );
};

export default LastVisitedHotels;
