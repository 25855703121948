import React from "react";
import { Link } from "react-router-dom";

const TrendingDestinations = () => {
  const destinations = [
    {
      name: "Tanzania Mainland",
      image:
        "/assets/images/bongo.jpg",
      country: "Tanzania",
      flag: "🇹🇿",
    },
    {
      name: "Zanzibar",
      image: "/assets/images/pexels-ukiyoo-1912620432-29205391.jpg",
      country: "Tanzania",
      flag: "znz",
    },
    {
      name: "Zanzibar City",
      image:
        "/assets/images/bongo.jpg",
      country: "Tanzania",
      flag: "🇹🇿",
    },
    {
      name: "Arusha",
      image: "/assets/images/pexels-ukiyoo-1912620432-29205391.jpg",
      country: "Tanzania",
      flag: "🇹🇿",
    },
    {
      name: "temeke",
      image: "/assets/images/pexels-ukiyoo-1912620432-29205391.jpg",
      country: "Kenya",
      flag: "🇰🇪",
    },
  ];

  const sectionStyle = {
    padding: "20px",
    textAlign: "center",
  };

  const gridStyle = {
    display: "grid",
    gridTemplateColumns: "1fr 1fr", // Two columns for the first row
    gridTemplateRows: "auto auto", // Two rows
    gap: "20px",
    justifyContent: "center",
    alignItems: "center",
  };

  const firstRowImageStyle = {
    gridRow: 1,
    width: "100%", // Cover full width of the screen
    height: "300px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "8px",
  };

  const secondRowGridStyle = {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)", // Three columns for the second row
    gap: "20px",
    justifyContent: "center",
    alignItems: "center",
  };

  const secondRowImageStyle = {
    width: "100%",
    height: "300px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    borderRadius: "8px",
  };

  const cardStyle = {
    textDecoration: "none",
    color: "inherit",
    overflow: "hidden",
    borderRadius: "8px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    transition: "transform 0.3s, box-shadow 0.3s",
  };

  const hoverEffect = {
    transform: "translateY(-5px)",
    boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
  };

  const infoStyle = {
    padding: "10px",
    background: "#fff",
    textAlign: "center",
  };

  const titleStyle = {
    fontSize: "18px",
    margin: "0",
  };

  const flagStyle = {
    fontSize: "14px",
    color: "#888",
  };

  return (
    <section style={sectionStyle}>
      <h2 style={{ textAlign: "left" }}>Trending Destinations</h2>
      <p style={{ textAlign: "left" }}>
        Most popular choices for travelers from Tanzania
      </p>
      <div style={gridStyle}>
        {destinations.slice(0, 2).map((destination, index) => (
          <Link
            to={`/HomeTest?location=${encodeURIComponent(destination.name)}`}
            key={index}
            style={{
              ...cardStyle,
              ":hover": hoverEffect, // Add hover effect
            }}
          >
            <div
              style={{
                ...firstRowImageStyle,
                backgroundImage: `url(${destination.image})`,
              }}
            ></div>
            <div style={infoStyle}>
              <h3 style={titleStyle}>{destination.name}</h3>
              <span style={flagStyle}>{destination.flag}</span>
            </div>
          </Link>
        ))}
      </div>
      <div style={secondRowGridStyle}>
        {destinations.slice(2).map((destination, index) => (
          <Link
            to={`/HomeTest?location=${encodeURIComponent(destination.name)}`}
            key={index}
            style={{
              ...cardStyle,
              ":hover": hoverEffect, // Add hover effect
            }}
          >
            <div
              style={{
                ...secondRowImageStyle,
                backgroundImage: `url(${destination.image})`,
              }}
            ></div>
            <div style={infoStyle}>
              <h3 style={titleStyle}>{destination.name}</h3>
              <span style={flagStyle}>{destination.flag}</span>
            </div>
          </Link>
        ))}
      </div>
    </section>
  );
};

export default TrendingDestinations;
